import { NavigationContextProvider } from "./navContent";
import Navbar from "./navbar";

const NavWrapper = () => {
  return (
    <NavigationContextProvider>
      <Navbar />
    </NavigationContextProvider>
  );
};

export default NavWrapper;
