import { Dialog, Transition } from "@headlessui/react";
import { Component, Fragment } from "react";

export default class ComingSoonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: true,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.isOpen = this.isOpen.bind(this);
  }

  isOpen() {
    return this.state.openModal;
  }
  
  open() {
    this.setState({ openModal: true });
    return this.isOpen();
  }

  close() {
    this.setState({ openModal: false });
    this.props.setOpenModal(false);
  }

  render() {
    return (
      <>
        <Transition.Root show={this.isOpen()} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => this.close()}
          >
            <div className="flex items-center justify-center min-h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay
                  className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity"
                  onClick={() => this.close()}
                />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <h2 className="text-xl font-bold mb-8 text-black" style={{marginTop: "-20px"}}>
                    Coming Soon {this.props.comingSoonDate}!
                  </h2>
                  <p className="text-black">
                    Watch this space.
                  </p>
                  <button
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={() => this.close()}
                  >
                    Close
                  </button>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

// export default OtpModal;
