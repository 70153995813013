export const CERTIFICATE_SYSCODE = {
  Reserved: 0,
  Sandbox_NETS_QR: 1,
  Sandbox_NETS_Click: 2,
  Sandbox_eNETS: 3,
  UAT_NETS_QR: 4,
  UAT_NETS_Click: 5,
  UAT_eNETS: 6,
  GO_LIVE_NETS_QR: 7,
  GO_LIVE_NETS_Click: 8,
  GO_LIVE_eNETS: 9,
}

export const CertificateSyscodeString = {
  [CERTIFICATE_SYSCODE.Reserved]: "Reserved",
  [CERTIFICATE_SYSCODE.Sandbox_NETS_QR]: "(Sandbox) NETS_QR",
  [CERTIFICATE_SYSCODE.Sandbox_NETS_Click]: "(Sandbox) NETS Click",
  [CERTIFICATE_SYSCODE.Sandbox_eNETS]: "(Sandbox) eNETS",
  [CERTIFICATE_SYSCODE.UAT_NETS_QR]: "(UAT) NETS QR",
  [CERTIFICATE_SYSCODE.UAT_NETS_Click]: "(UAT) NETS Click",
  [CERTIFICATE_SYSCODE.UAT_eNETS]: "(UAT) eNETS",
  [CERTIFICATE_SYSCODE.GO_LIVE_NETS_QR]: "(Go Live) NETS QR",
  [CERTIFICATE_SYSCODE.GO_LIVE_NETS_Click]: "(Go Live) NETS Click",
  [CERTIFICATE_SYSCODE.GO_LIVE_eNETS]: "(Go Live) eNETS",
};

export const CertificateSyscodeMap = {
  staging: [
    CERTIFICATE_SYSCODE.UAT_NETS_QR,
    CERTIFICATE_SYSCODE.UAT_NETS_Click,
    CERTIFICATE_SYSCODE.UAT_eNETS
  ],
  sandbox: [
    CERTIFICATE_SYSCODE.Sandbox_NETS_QR,
    CERTIFICATE_SYSCODE.Sandbox_NETS_Click,
    CERTIFICATE_SYSCODE.Sandbox_eNETS
  ],
  production: [
    CERTIFICATE_SYSCODE.GO_LIVE_NETS_QR,
    CERTIFICATE_SYSCODE.GO_LIVE_NETS_Click,
    CERTIFICATE_SYSCODE.GO_LIVE_eNETS
  ]
};