import { createContext, useState } from "react";

const tabs = [
    { name: "Staging", value: 0 },
    { name: "Production", value: 1 },
  ];

export const projectsTabsContext = createContext();
export const projectsCurrentTabContext = createContext();
export const ProjectsContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const contextValue = { currentTab, setTab };

  return (
    <projectsTabsContext.Provider value={{ tabs }}>
      <projectsCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </projectsCurrentTabContext.Provider>
    </projectsTabsContext.Provider>
  );
};
