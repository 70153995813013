import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ArrowLeft, HambergerMenu, People, SearchNormal } from "iconsax-react";
import { Fragment, useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import {
  CurrentNavigationRouteContext,
  NavigationRoutesContext,
} from "./navContent";
import SidebarItem from "./sidebarItem";
import { removeUser } from "../../services/tokenService";

// logout for user
const handleLogout = (props) => {
  if (props === "logout") {
    removeUser();
    window.location = `/developerlogin`;
  }
};

const user = {
  name: "Anne",
  email: "anne@beveat.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const userNavigation = [
  // { name: "Profile", onclick: "", href: "#" },
  // { name: "Settings", onclick: "", href: "#" },
  { name: "Sign out", onclick: "logout", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { navigationRoutes } = useContext(NavigationRoutesContext);
  const { currentRoute, setCurrentRoute } = useContext(
    CurrentNavigationRouteContext
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <div
        className={`top-0 bottom-0 w-[21vw] rounded-md m-1 bg-gray-800 p-2 text-white fixed z-40 ease-in-out duration-300 overflow-auto`}
        style={{
          left: showSidebar ? "0" : "-100%",
          backgroundColor: "#1f2937",
        }}
        ref={(el) => {
          if (el)
            el.style.setProperty("background-color", "#1f2937", "important");
        }}
      >
        <div className="flex justify-end">
          <button
            className="flex text-4xl text-white items-center cursor-pointer fixed z-50"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <span className="sr-only">Close Sidebar</span>
            <ArrowLeft className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <h3 className="mt-20 text-4xl font-semibold text-white">
          {navigationRoutes.map((item) => (
            <SidebarItem
              key={item.title}
              item={item}
              callback={(title) => {
                setShowSidebar(!showSidebar);
                setCurrentRoute(title);
              }}
              selectedTitle={currentRoute}
            />
          ))}
        </h3>
      </div>
      {/* Sidebar end */}

      <Disclosure as="nav" className="bg-white shadow-sm">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="sm:flex sm:items-center">
                    <button
                      type="button"
                      onClick={() => setShowSidebar(!showSidebar)}
                      className="bg-slate-900 p-1 rounded-md text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-950"
                    >
                      <span className="sr-only">Sidebar</span>
                      <HambergerMenu className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* <div className="flex-1 px-4 flex justify-between">
                    <div className="flex-1 flex">
                      <form
                        className="w-full flex md:ml-0"
                        action="#"
                        method="GET"
                      >
                        <label htmlFor="search-field" className="sr-only">
                          Search
                        </label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                            <SearchNormal
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            id="search-field"
                            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                            placeholder="Search"
                            type="search"
                            name="search"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-900"
                  >
                    <span className="sr-only">People</span>
                    <People className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                onClick={() => handleLogout(item.onclick)}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <HambergerMenu
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <HambergerMenu
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Search</span>
                    <SearchNormal className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">People</span>
                    <People className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={() => handleLogout(item.onclick)}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Outlet />
    </div>
  );
};

export default Navbar;
