import axios from "axios";
import React, { useState, useEffect } from "react";
import Card from './detailsCard'
import { useLocation  } from "react-router-dom";
import { urls, commonConfigs } from "../../../config/config";

const SandboxDetails = () => {
  const [load, setLoad] = useState(true);
  const location = useLocation();
  const { developerSsoId, developerProjId, sandboxIndex } = location.state || {};
  const [data, setData] = useState([]);
  const getOneSandboxUrl = urls.sandboxUrl + commonConfigs.apiUrls.GetOneDeveloperProjects(developerSsoId, developerProjId)

  async function getSandbox() {
    await axios
    .get(getOneSandboxUrl, { headers: commonConfigs.apiHeader })
    .then((res) => {
      // console.log(res.data.result.data);
      setData(res.data.result.data);
      setLoad(false);
    })
    .catch((err) => {
      console.log(err);
  })}

  useEffect(() => { 
    getSandbox();
  }, []);

  if (!load) {
    return (
      <div>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          {/* calling the Card component */}
          <div className="mt-16 flex flex-grow gap-x-1 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '2fr 2fr 2fr'}}>
            {/* <Card/> */}
            <Card data={data} index = {sandboxIndex} />
          </div>
        </div>
      </div>
    );
  }
};

export default SandboxDetails;