import PricingPlanLayout from './pricingPlanLayout';
import { SubscriptionContextProvider } from '../../context/subscriptionContext';

// this displays the layout for project page
const PricingPlanPage = () => {
  return (
    <SubscriptionContextProvider>
      <PricingPlanLayout />
    </SubscriptionContextProvider>
  );
};

export default PricingPlanPage;