// import commonConfigs from '../../config';
// import axios from 'axios';

export const isLogin = async () => {
  var status = false;
  var user = localStorage.getItem("user");

  if (user) {
    // await axios.get(commonConfigs.apiUrls.tokenExpiry(),{headers: commonConfigs.apiHeader}).then(res => {
    //     status = true;
    //     return status;
    //     }).catch(err =>{
    //         status = false;
    //         alert('Your session has expired. Please Login again.')
    //         window.location = '/portal/login'
    //         return status;
    //     });
    //     //console.log(status);
    //     return status;
    console.log("success");
    status = true;
    return status;
  } else if (!user) {
    status = false;
    alert("You are not authenticated. Please Login.");
    window.location = "/login";
    return status;
  } else {
    status = false;
    alert("You are not authenticated. Please Login.");
    window.location = "/login";
    return status;
  }
};
