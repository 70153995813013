import React, { useState, useEffect } from "react";
import UpdateSandboxModal from "./updateSandboxModal";
import { Link, useNavigate } from "react-router-dom";
import CreateCertificateDetails from "../../subscription_module/pricing_plan_module/components/certification/createCertificateDetailsModal";
import { getUser } from "../../../services/tokenService";
import axios from "axios";
import { commonConfigs, urls } from "../../../config/config";
import { INVOICE_PLAN_SYSCODE } from "../../../commons/constants/invoicePlanTypeSyscode";

const DetailsCard = ({ data }) => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [certificates, setCertificates] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const accountId = getUser()?.account_id;

  const getDeveloperCertificatesUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.getAllDeveloperCertificates(accountId);

  async function getDeveloperCertificates() {
    await axios
      .get(getDeveloperCertificatesUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        console.log(res.data.result.data);
        setCertificates(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDeveloperCertificates().catch((err) => {
      console.log("err for getting developer certificates: " + err);
    });
  }, []);

  const handleViewSecret = () => {
    alert(`Sandbox API Key: ${data.developer_proj_key_value}`);
  };

  const renderEnabledAPIs = () => {
    const projects = data.access.map((project) => project.project_name);
    if (projects.length === 0) {
      return "None";
    } else if (projects.length === 1) {
      return projects[0];
    } else {
      return projects.join(", ");
    }
  };

  const onGetCertificateClick = () => {
    data.invoice_plan_id = "6557d67e-9dbe-40d3-8ce7-34b3a58cb856"; //TODO: remove after implement stg price plan
    const developerCertificate = certificates.find(
      (certificate) =>
        certificate.invoice_plan_id == data.invoice_plan_id &&
        certificate.developer_proj_id == data.developer_proj_id
    );
    if (developerCertificate) {
      navigate("/subscriptions/certification", {
        state: {
          certificationPlanId: data.invoice_plan_id,
          environment: "sandbox",
          devProjId: data.developer_proj_id,
        },
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div
        style={{
          marginRight: "10px",
          marginLeft: "80px",
          width: "275%",
          marginTop: "-60px",
          color: "black",
        }}
      >
        <div
          className="mb-4"
          style={{
            textAlign: "left",
            marginBottom: "40px",
            fontSize: "x-large",
            marginLeft: "-3.5%",
          }}
        >
          Sandbox Project Details
        </div>
        <div
          className="mb-4 font-bold"
          style={{ textAlign: "left", marginTop: "35px", fontSize: "17px" }}
        >
          {data.developer_proj_name}
        </div>
        <div
          className="bg-white rounded-xl"
          style={{
            height: "inherit",
            width: "100%",
            minHeight: 0,
            minWidth: 0,
            boxShadow: "5px 10px #EBECF0",
            color: "black",
            fontSize: "16px",
            borderWidth: "0.5px",
            borderColor: "black",
            borderStyle: "solid",
            marginTop: "-10px",
          }}
        >
          <div
            className="p-4"
            style={{
              padding: "5px",
              display: "grid",
              gridTemplateAreas: `'text1 text1 text1' 'text2 text2 text2''text3 text3 text3''text4 text4 text4''text5 text5 text5''button1 button1 button1'`,
              gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
              gap: 4,
              maxWidth: "inherit",
            }}
          >
            <div
              style={{
                gridArea: "text1",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <strong>Developer Project Id</strong>: {data.developer_proj_id}
            </div>
            <div
              style={{
                gridArea: "text3",
                textAlign: "left",
                marginTop: "15px",
              }}
            >
              <strong>Sandbox API Key:</strong>
              <button
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "5px",
                  marginLeft: "20px",
                }}
                className="bg-blue text-white-500 hover:bg-blue-500 hover:text-white outline py-2 px-10 font-bold mr-6 text-sm mt-2"
                onClick={handleViewSecret}
              >
                Click here to view API key
              </button>
            </div>
            <div
              style={{
                gridArea: "text4",
                textAlign: "left",
                marginTop: "20px",
              }}
            >
              <strong>API(s) Enabled</strong>: {renderEnabledAPIs()}
            </div>
            <div
              style={{
                gridArea: "text5",
                textAlign: "left",
                marginTop: "20px",
              }}
            >
              <strong>Project Website URL</strong>:{" "}
              {data.developer_proj_website_url}
            </div>
            {/* buttons */}
            <div style={{ gridArea: "button1" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  marginBottom: "-20px",
                }}
              >
                <button
                  className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-10 rounded-full font-bold mr-6 text-sm mt-8 mb-5"
                  onClick={onGetCertificateClick}
                >
                  Get Course Certificate
                </button>
                <button
                  className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-10 rounded-full font-bold mr-6 text-sm mt-8 mb-5"
                  onClick={() => setUpdateModalOpen(true)}
                >
                  Update
                </button>
                {isUpdateModalOpen && (
                  <UpdateSandboxModal
                    setOpenModal={setUpdateModalOpen}
                    load={setLoad}
                    data={data}
                  />
                )}
                <Link to="/homepage">
                  <button className="hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 py-2 px-10 rounded-full font-bold text-sm mt-8 mb-5">
                    Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <CreateCertificateDetails
          setOpenModal={setOpen}
          planName={""}
          invoicePlanSyscode={INVOICE_PLAN_SYSCODE.API_Plan_UAT}
          planId={data.invoice_plan_id}
          devProjId={data.developer_proj_id}
          environment={"sandbox"}
        />
      )}
    </>
  );
};

export default DetailsCard;
