export const INVOICE_PLAN_SYSCODE = {
  Reserved: 0,
  Certification_Plan_UAT: 1,
  API_Plan_UAT: 2,
  Tech_Support_Plan_UAT: 3,
  Certification_Plan_Go_Live: 101,
  API_Plan_Go_Live: 102,
  Tech_Support_Plan_Go_Live: 103,
}

export const InvoicePlanSyscodeString = {
  [INVOICE_PLAN_SYSCODE.Reserved]: "Reserved",
  [INVOICE_PLAN_SYSCODE.Certification_Plan_UAT]: "(UAT) Developer Certification Plan",
  [INVOICE_PLAN_SYSCODE.API_Plan_UAT]: "(UAT) API Plan",
  [INVOICE_PLAN_SYSCODE.Tech_Support_Plan_UAT]: "(UAT) Tech Support Plan",
  [INVOICE_PLAN_SYSCODE.Certification_Plan_Go_Live]: "(Go Live) Developer Certification Plan",
  [INVOICE_PLAN_SYSCODE.API_Plan_Go_Live]: "(Go Live) API Plan",
  [INVOICE_PLAN_SYSCODE.Tech_Support_Plan_Go_Live]: "(Go Live) Tech Support Plan",
};