const PortfolioCard = ({ data, index }) => {
  const websiteUrl = data.developer_proj_website_url || "https://www.example.com/";

  return (
    <a
      href={websiteUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div
        className="bg-white rounded-xl"
        style={{
          height: "300px",
          width: "450px",
          minHeight: 0,
          minWidth: 0,
          boxShadow: "5px 10px #EBECF0",
          marginTop: "20px",
          borderWidth: "0.5px",
          borderColor: "black",
          borderStyle: "solid",
          cursor: "pointer",
          position: "relative" // Make the container relative for the overlay
        }}
      >
        <div
          className="p-3"
          style={{
            fontSize: "16px",
            display: "grid",
            gridTemplateRows: "1fr auto",
            gap: 4,
            maxWidth: "inherit",
            color: "black",
            height: "100%",
          }}
        >
          <div style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
            <iframe
              src={websiteUrl}
              style={{ 
                width: "200%", 
                height: "200%", 
                transform: "scale(0.5)", 
                transformOrigin: "0 0", 
                border: "none" 
              }}
            ></iframe>
            {/* Overlay element */}
            <a
              href={websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1, // Ensure the overlay is above the iframe
                background: "transparent"
              }}
            ></a>
          </div>
          <div className="font-bold mt-2" style={{ textAlign: "center" }}>
            {data.developer_proj_name}
          </div>
        </div>
      </div>
    </a>
  );
};

export default PortfolioCard;
