import { useContext, useEffect } from "react";
import { classNames } from "../../../commons/utils/helper";
import { useLocation } from "react-router";
import { subscriptionTabsContext, subscriptionCurrentTabContext } from "../context/subscriptionContext";

const SubscriptionMenuTabs = () => {
  const { tabs } = useContext(subscriptionTabsContext);
  const location = useLocation();
  const { currentTab, setTab, setTabByValue } = useContext(subscriptionCurrentTabContext);

  useEffect(() => {
    if (location.state && location.state.tabValue !== undefined) {
      setTabByValue(location.state.tabValue);
    }
  }, []);

  return (
    <div className="sm:block pb-4">
      <nav className="flex space-x-4" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            onClick={() => setTab(tab)}
            key={tab.name}
            className={classNames(
              tab.name === currentTab.name
                ? "underline text-black font-bold"
                : "text-gray-700 font-bold hover:text-yellow-800",
              "px-2 py-2 font-medium text-lg rounded-md"
            )}
            aria-current={tab.name === currentTab.name ? "page" : undefined}
          >
            {tab.name}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default SubscriptionMenuTabs;
