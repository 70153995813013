import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  CertificateSyscodeMap
} from "../../../../commons/constants/certificateTypeSyscode";
import { commonConfigs, environmentHeaders, environmentUrls, urls } from "../../../../config/config";

export default function CertificationForm({
  tab,
  certificateData,
  environment,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const certificateSyscode = CertificateSyscodeMap[environment]?.[tab.value];
  certificateData = certificateData.find(
    (certificate) => certificate.certificate_syscode === certificateSyscode
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      txn_id: certificateData.txn_id,
      txn_retrieval_ref: certificateData.txn_retrieval_ref,
    },
  });

  let certifyUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.updateCertificate();

  function formatData(data) {
    var newData = data;
    newData.developer_certificate_id = certificateData.developer_certificate_id;
    newData.developer_email = certificateData.developer_email;
    newData.developer_name = certificateData.developer_name;
    // console.log("formatdata", newData);
    return newData;
  }

  async function validateNets(retrievalRef, txnId) {
    const url = environmentUrls[environment]
    const header = environmentHeaders[environment]
    const apiUrls = {
      0: commonConfigs.apiUrls.validateNetsQrTxn,
      1: commonConfigs.apiUrls.validateNetsClickTxn,
      2: commonConfigs.apiUrls.validateEnetsTxn,
    };
    const apiUrl = apiUrls[tab.value];
    const validateNetsUrl = 
    url + apiUrl(retrievalRef, txnId);

    return await axios
      .get(validateNetsUrl, { headers: header })
      .then((res) => {
        console.log(res.data.result.data);
        return res.data.result.data;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    var newData = formatData(data);

    // console.log("formatted data", newData);
    const response = await validateNets(
      newData.txn_retrieval_ref,
      newData.txn_id
    );
    if (!response) {
      setIsDisabled(false);
      return;
    }
    if (response.is_valid) {
      axios
        .put(certifyUrl, newData, {
          headers: commonConfigs.resourceOwnerApiHeader,
        })
        .then((res) => {
          try {
            if (res.data.status !== "success") {
              alert("danger");
            } else {
              alert("success");
            }
          } catch (err) {
            console.log(err);
          } finally {
            setIsDisabled(false); // Enable the submit button after API call
            window.location.reload();
          }
        })
        .catch((err) => {
          const err_msg = JSON.stringify(err.response.data.result.message);
          alert(`Error: ${err_msg}`);
          setIsDisabled(false); // Enable the submit button if there's an error
        });
    } else {
      alert(`${response.message}`);
      setIsDisabled(false);
      return;
    }
  };

  return (
    <div className="bg-white rounded-xl p-6 shadow-md border border-gray-300">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 text-left text-black">
          <label
            htmlFor="txn_id"
            className="block text-sm font-medium text-gray-700"
          >
            Transaction ID (From Full-Stack Developer Project)
          </label>
          <input
            type="text"
            name="txn_id"
            id="txn_id"
            disabled={certificateData.txn_id}
            className={`block w-full pr-10 sm:text-sm rounded-md ${
              !errors.txn_id
                ? "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                : "border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500"
            } ${
              certificateData.txn_id ? "bg-gray-200 text-gray-700" : "bg-white"
            }`}
            placeholder="Enter txn_id"
            {...register("txn_id")}
            style={{ marginBottom: "20px" }}
          />
          {errors.txn_id?.type === "required" && errorIcon}
        </div>
        <div className="mb-4 text-left text-black">
          <label
            htmlFor="txn_retrieval_ref"
            className="block text-sm font-medium text-gray-700"
          >
            Transaction {tab.name} Retrieval Reference (From Full-Stack
            Developer Project)
          </label>
          <input
            type="text"
            name="txn_retrieval_ref"
            id="txn_retrieval_ref"
            disabled={certificateData.txn_retrieval_ref}
            className={`block w-full pr-10 sm:text-sm rounded-md ${
              !errors.txn_retrieval_ref
                ? "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                : "border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500"
            } ${
              certificateData.txn_retrieval_ref
                ? "bg-gray-200 text-gray-700"
                : "bg-white"
            }`}
            placeholder="Enter txn_retrieval_ref"
            {...register("txn_retrieval_ref")}
            style={{ marginBottom: "20px" }}
          />
          {errors.txn_retrieval_ref?.type === "required" && errorIcon}
        </div>
        <div className="text-right">
          <button
            type="submit"
            disabled={isDisabled || certificateData.txn_id}
            className={`py-2 px-6 rounded-md text-right font-bold ${
              isDisabled || certificateData.txn_id
                ? "bg-green-600 text-white cursor-not-allowed" // Styles for the disabled state
                : "hover:bg-green-500 text-green-500 hover:text-white outline outline-green-500 text-green" // Styles for the enabled state
            }`}
          >
            {isDisabled || certificateData.txn_id ? "Certified" : "Certify"}
          </button>
        </div>
      </form>
    </div>
  );
}
