import { createContext, useState } from "react";

const tabs = [
    { name: "NETS QR", value: 0 },
    { name: "NETS Click", value: 1 },
    { name: "eNETS", value: 2 }
  ];

export const certificationTabsContext = createContext();
export const certificationCurrentTabContext = createContext();
export const CertificationContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const setTabByValue = (value) => {
    const matchingTab = tabs.find((tab) => tab.value === value);
    if (matchingTab) {
      setTab(matchingTab);
    }
  };
  const contextValue = { currentTab, setTab, setTabByValue };
  return (
    <certificationTabsContext.Provider value={{ tabs }}>
      <certificationCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </certificationCurrentTabContext.Provider>
    </certificationTabsContext.Provider>
  );
};
