import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Typography } from "@material-tailwind/react";
import { urls, commonConfigs } from "../../../config/config";
import {
  PlatformSyscodeString,
  PLATFORM_SYSCODE,
} from "../../../commons/constants/platformTypeSyscode";
import { InfoCircle } from "iconsax-react";
import { getUser } from "../../../services/tokenService";
import { getDoublewriteConfig } from "../../../commons/utils/getDoublewriteConfig";
import { INVOICE_PLAN_SYSCODE } from "../../../commons/constants/invoicePlanTypeSyscode";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const CreateProject = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [isCodeApplied, setIsCodeApplied] = useState(false);
  const [isVoucherValid, setIsVoucherValid] = useState(false);
  const [optionLoad, setOptionLoad] = useState(true);
  const [projects, setProjectsData] = useState([]);
  const [developerProjName, setDeveloperProjName] = useState();
  const [environment, setEnvironment] = useState();
  const [stagingProjects, setStagingProjects] = useState([]);
  const [productionProjects, setProductionProjects] = useState([]);
  const [industryApiOptions, setIndustryApiOptions] = useState([]);
  const [organisationApiOptions, setOrganisationApiOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const presetOptions = location.state?.presetOptions;
  const accountId = getUser()?.account_id;
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      environment: presetOptions?.environment,
      subscription_plan: presetOptions?.subscriptionId || null,
      industryAccess: presetOptions?.industryApiOptions || [],
      organisationAccess:
        presetOptions?.organisationApiOptions.filter(
          (project) =>
            !(project.label === "beveatshop" || project.label === "spaerohub")
        ) || [],
    },
  });
  const createProjectUrl =
    urls.resourceOwnerUrl + commonConfigs.apiUrls.CreateDeveloperProject();
  const getStagingProjectsUrl =
    urls.baseUrl + commonConfigs.apiUrls.getProjects();
  const getProductionProjectsUrl =
    urls.prodUrl + commonConfigs.apiUrls.getProjects();

  useEffect(() => {
    async function getStagingProjectsData() {
      await axios
        .get(getStagingProjectsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          // console.log(res.data.result.data);
          setProjectsData(res.data.result.data);
          setStagingProjects(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getProductionProjectsData() {
      await axios
        .get(getProductionProjectsUrl, { headers: commonConfigs.prodApiHeader })
        .then((res) => {
          // console.log(res.data.result.data);
          setProjectsData(res.data.result.data);
          setProductionProjects(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (optionLoad) {
      if (presetOptions?.environment.value === "0") {
        getStagingProjectsData().catch((err) => {
          console.log("err for staging projects : " + err);
        });
      } else if (presetOptions?.environment.value === "1") {
        getProductionProjectsData().catch((err) => {
          console.log("err for production projects : " + err);
        });
      }
    }
  }, []);

  async function validateVoucher(voucherCode) {
    const validateVoucherUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.validateVoucher(
        accountId,
        voucherCode,
        presetOptions?.subscriptionId.value
      );
    await axios
      .get(validateVoucherUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        const isValid = res.data.result.data.is_valid;
        if (isValid) {
          setIsCodeApplied(true);
          setIsVoucherValid(true);
          alert("Voucher code applied successfully!");
        } else {
          alert(`${res.data.result.data.message}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (environment) {
      if (environment === "0") {
        setProjectsData(stagingProjects);
      } else if (environment === "1") {
        setProjectsData(productionProjects);
      } else {
        setProjectsData(null);
      }
    }
  }, [environment, stagingProjects, productionProjects]);

  useEffect(() => {
    if (projects) {
      // console.log(projects);
      const industryApiOptions = projects
        .filter(
          (project) =>
            project.project_billing_config_id == 1 &&
            project.project_name.toLowerCase().includes("industry")
        )
        .map((project) => ({
          value: project.project_id,
          label: project.project_name,
        }));
      const organisationApiOptions = projects
        .filter(
          (project) =>
            project.project_billing_config_id == 1 &&
            !project.project_name.toLowerCase().includes("industry")
        )
        .map((project) => ({
          value: project.project_id,
          label: project.project_name,
        }));
      setIndustryApiOptions(industryApiOptions);
      setOrganisationApiOptions(organisationApiOptions);
    }
  }, [projects]);

  const environmentOptions = [
    { value: "0", label: "Staging" },
    { value: "1", label: "Production" },
  ];

  const options_plan = [
    { value: null, label: "None" },
    { value: "1", label: "IHL-NETS-Fintech Full Stack Developer Course" },
    // { value: "2", label: "Subscription 2" },
  ];

  const platformTypeOptions = Object.entries(PLATFORM_SYSCODE).map(
    ([key, value]) => ({
      value: value,
      label: PlatformSyscodeString[value],
    })
  );

  const handleApplyVoucher = (e) => {
    e.preventDefault();
    if (!voucherCode) {
      alert("Please enter a voucher code.");
      return;
    }
    validateVoucher(voucherCode);
  };

  const handleRemoveVoucher = (e) => {
    e.preventDefault();
    setVoucherCode("");
    setIsCodeApplied(false);
  };

  const handleCancel = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    navigate(-1);
  };

  function formatData(data) {
    var newData = data;
    var developerSsoId = localStorage.getItem("ssoId");
    var subscriptionId = data.subscription_plan.value;
    var platformSyscode = data.platform_syscode.value;

    let access = [];
    if (data.industryAccess) {
      access = data.industryAccess.map((item) => ({ project_id: item.value }));
    }
    if (data.organisationAccess) {
      const organisationAccess = data.organisationAccess.map((item) => ({
        project_id: item.value,
      }));
      access = [...access, ...organisationAccess];
    }
    const projects = [
      ...presetOptions.industryApiOptions,
      ...presetOptions.organisationApiOptions,
    ].filter(
      (project) =>
        project.label === "beveatshop" || project.label === "spaerohub"
    );
    if (projects) {
      projects.forEach((project) => {
        access.push({ project_id: project.value });
      });
    }
    let invoicePlanSyscode;
    if (presetOptions.invoicePlanSyscode != undefined) {
      invoicePlanSyscode = presetOptions.invoicePlanSyscode;
    } else {
      invoicePlanSyscode = INVOICE_PLAN_SYSCODE.API_Plan_Go_Live;
    }
    newData.invoice_plan_id = subscriptionId;
    newData.invoice_plan_syscode = invoicePlanSyscode;
    newData.developer_sso_id = developerSsoId;
    newData.developer_proj_name = developerProjName;
    newData.platform_syscode = platformSyscode;
    newData.access = access;
    newData.voucher_code = voucherCode;
    console.log("formatdata", newData);
    return newData;
  }

  async function doublewriteProjects(body, devProj) {
    const { url, headers } = getDoublewriteConfig(process.env.REACT_APP_ENV);
    var doublewriteData = body;

    doublewriteData.developer_proj_id = devProj.developer_proj_id;
    doublewriteData.developer_subscription_id =
      devProj.developer_subscription_id;
    doublewriteData.signature_scrt_key = devProj.developer_proj_scrt_key;
    doublewriteData.developer_proj_key_value = devProj.developer_proj_key_value;
    doublewriteData.access = devProj.access;

    const doublewriteUrl =
      url + commonConfigs.apiUrls.doublewriteDeveloperProject();
    try {
      await axios.post(doublewriteUrl, doublewriteData, { headers: headers });
    } catch (error) {
      console.error("Error doublewriting project:", error);
    }
  }

  async function createInvoice(data, devProj) {
    const createInvoiceUrl =
      urls.resourceOwnerUrl + commonConfigs.apiUrls.createInvoice();
    let body;
    if (data.voucher_code) {
      const getVoucherUrl =
        urls.resourceOwnerUrl +
        commonConfigs.apiUrls.getVoucher(data.voucher_code);
      await axios
        .get(getVoucherUrl, {
          headers: commonConfigs.resourceOwnerApiHeader,
        })
        .then((res) => {
          try {
            body = {
              developer_proj_id: devProj.developer_proj_id,
              payment_id: `course_voucher_${Date.now()}`,
              invoice_plan_id: data.invoice_plan_id,
              invoice_plan_syscode: data.invoice_plan_syscode,
              account_id: accountId,
              discount_type: 0,
              discount_percent: 0,
              discount_amt: 0,
              voucher_type: res.data.result.data.voucher_type,
              voucher_code: data.voucher_code,
              voucher_amt: res.data.result.data.voucher_value,
              org_id: process.env.REACT_APP_RESOURCE_OPENAPIPAAS_ORG_ID,
            };
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          const err_msg = JSON.stringify(err.response.data.result.message);
          alert(`Error: ${err_msg}`);
          setIsDisabled(false); // Enable the submit button if there's an error
          return;
        });
    }
    await axios
      .post(createInvoiceUrl, body, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        alert("success");
        setIsDisabled(false); // Enable the submit button after API call
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error: ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    if (!isVoucherValid || !isCodeApplied) {
      // Display an error if the voucher code is not applied or invalid
      setIsDisabled(false);
      alert("Please apply a valid voucher code before proceeding.");
      return;
    }
    const newData = formatData(data);

    // console.log("newData", newData);

    axios
      .post(createProjectUrl, newData, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then(async (res) => {
        await createInvoice(newData, res.data.result.data);
        await doublewriteProjects(newData, res.data.result.data);
        window.location.href = "/projects";
      })
      .catch((err) => {
        alert(`Error: ${err}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  if (!load) {
    return (
      <div>
        <div
          className="mt-16 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2"
          style={{ textAlign: "-webkit-center", marginTop: "-2px" }}
        >
          <div
            className="bg-white border-white border rounded-2xl m-5 p-4"
            style={{
              height: "inherit",
              width: "65%",
              minHeight: 0,
              minWidth: 0,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="mt-2">
                  <label
                    htmlFor="developer_proj_name"
                    className="block text-sm font-medium text-gray-600 mt-8"
                    style={{ textAlign: "left", marginBottom: "5px" }}
                  >
                    Project Name
                  </label>
                  <div
                    className="relative rounded-md shadow-sm"
                    style={{ color: "black" }}
                  >
                    <input
                      type="text"
                      name="developer_proj_name"
                      id="developer_proj_name"
                      className={
                        !errors.developer_proj_name
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder="Enter Name of Project"
                      {...register("developer_proj_name", { required: true })}
                      value={developerProjName}
                      onChange={(e) => setDeveloperProjName(e.target.value)}
                    />
                    {errors.developer_proj_name?.type === "required" &&
                      errorIcon}
                  </div>
                </div>
              </div>
              <label
                htmlFor="platform_syscode"
                className="block text-sm font-medium text-gray-600 mt-4"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Platform Type
              </label>
              <div className="sm:col-span-2" style={{ color: "black" }}>
                <Controller
                  name="platform_syscode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={platformTypeOptions}
                      isSearchable={true}
                      placeholder="Select Platform Type"
                      className="text-sm"
                    />
                  )}
                />
              </div>
              <label
                htmlFor="environment"
                className="block text-sm font-medium text-gray-600 mt-4"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Project Environment
              </label>
              <div className="sm:col-span-2" style={{ color: "black" }}>
                <Controller
                  name="environment"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={environmentOptions}
                      isSearchable={true}
                      isDisabled={!!presetOptions}
                      placeholder="Select Environment"
                      className="text-sm"
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        // console.log(selectedOption);
                        if (selectedOption) {
                          setEnvironment(selectedOption.value);
                        }
                      }}
                    />
                  )}
                />
              </div>
              <label
                htmlFor="developer_proj_access"
                className="block text-sm font-medium text-gray-600 mt-4"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Industry API(s)
              </label>
              <div className="sm:col-span-2" style={{ color: "black" }}>
                <Controller
                  name="industryAccess"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={industryApiOptions}
                      isDisabled={!!presetOptions?.industryApiOptions}
                      isSearchable={true}
                      isMulti={true}
                      placeholder="Select API"
                      className="text-sm"
                    />
                  )}
                />
              </div>
              <label
                htmlFor="developer_proj_access"
                className="block text-sm font-medium text-gray-600 mt-4"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Organisation API(s)
              </label>
              <div className="sm:col-span-2" style={{ color: "black" }}>
                <Controller
                  name="organisationAccess"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={organisationApiOptions}
                      isDisabled={!!presetOptions?.organisationApiOptions}
                      isSearchable={true}
                      isMulti={true}
                      placeholder="Select API"
                      className="text-sm"
                    />
                  )}
                />
              </div>
              <label
                htmlFor="developer_proj_website_url"
                className="block text-sm font-medium text-gray-600 mt-4"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Project Website URL
              </label>
              <div className="relative rounded-md shadow-sm mb-2 text-black">
                <input
                  type="text"
                  name="developer_proj_website_url"
                  id="developer_proj_website_url"
                  className={
                    !errors.developer_proj_website_url
                      ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                      : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                  }
                  placeholder="Enter Project Website URL"
                  {...register("developer_proj_website_url")}
                  style={{ marginBottom: "20px" }}
                />
                {errors.developer_proj_website_url?.type === "required" &&
                  errorIcon}
              </div>

              <div
                className="block text-sm font-medium text-gray-600 mt-4"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Subscription Plan
              </div>

              <div
                className="mt-1 sm:col-span-2 mb-4"
                style={{ color: "black" }}
              >
                <Controller
                  name="subscription_plan"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={options_plan}
                      isDisabled={!!presetOptions?.subscriptionId}
                      isSearchable={true}
                      placeholder="Select Subscription Plan"
                      className="text-sm"
                    />
                  )}
                />
              </div>

              <div
                className="mt-1 sm:col-span-2 mb-8"
                style={{ color: "black" }}
              >
                <label
                  htmlFor="voucher_code"
                  className="block text-sm font-medium text-gray-600"
                  style={{ textAlign: "left", marginBottom: "5px" }}
                >
                  Voucher Code:
                </label>
                <div className="relative text-black">
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="voucher_code"
                      id="voucher_code"
                      value={voucherCode}
                      onChange={(e) => setVoucherCode(e.target.value)}
                      className={
                        !errors.voucher_code
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder="Enter Voucher Code"
                      disabled={isCodeApplied}
                      required
                    />
                    {isCodeApplied ? (
                      <button
                        onClick={handleRemoveVoucher}
                        className="ml-2 w-44 px-4 py-2 hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 rounded-md"
                      >
                        Remove Voucher
                      </button>
                    ) : (
                      <button
                        onClick={handleApplyVoucher}
                        className="ml-2 w-44 px-4 py-2 hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 rounded-md"
                      >
                        Apply Voucher
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* checkbox */}
              {/* <div style={{ textAlign: "left", marginLeft: "-1.8%", marginTop: "-20px" }}>
                <Checkbox
                  className="border-black border-1"
                  label={
                    <div>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal"
                        style={{ marginLeft: "-3px" }}
                      >
                        I grant permission for this project to be made and
                        readily used
                      </Typography>
                    </div>
                  }
                  required={true}
                />
              </div> */}

              {/* create button */}
              <div>
                <Link to="#" onClick={handleCancel}>
                  <button
                    type="button"
                    className="hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 font-bold py-2 px-12 rounded-full mt-10"
                    disabled={isDisabled}
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="submit"
                  className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full ml-5 mt-10"
                  disabled={isDisabled}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default CreateProject;
