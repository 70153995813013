import axios from "axios";
import React, { useEffect, useState } from "react";
import { commonConfigs, urls } from "../../../config/config.js";
import { getUser } from "../../../services/tokenService.js";
import Card from "../components/portfolioCard.jsx";

const PortfoliosLayout = ({}) => {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const mobileNum = getUser()?.mobile;
  let getAllPortfolioProjectsUrl = ``;
  let apiHeaders;

  if (process.env.REACT_APP_ENV === "staging") {
    getAllPortfolioProjectsUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.getAllPortfolioProjects(mobileNum);
    apiHeaders = commonConfigs.apiHeader;
  } else {
    getAllPortfolioProjectsUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.getAllPortfolioProjects(mobileNum);
    apiHeaders = commonConfigs.prodApiHeader;
  }

  async function getAllPortfolioProjects() {
    await axios
      .get(getAllPortfolioProjectsUrl, { headers: apiHeaders })
      .then((res) => {
        // console.log(res.data.result.data);
        setData(res.data.result.data);
        setDataList(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function onChangeSearch(e) {
    // console.log(e.target.value);
    var results = data.filter((project) =>
      project.developer_proj_name
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    // console.log(results);
    setInputValue(e.target.value);
    setDataList(results);
  }

  useEffect(() => {
    if (load) {
      getAllPortfolioProjects();
    }
  }, []);

  if (!load) {
    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  fontSize: "40px",
                  letterSpacing: "30px",
                  fontWeight: "800",
                  color: "#3C4B64",
                }}
              >
                <h1>PORTFOLIO</h1>
                <h1>PROJECTS</h1>
              </div>
            </div>
            <div className="flex flex-col items-start mt-4 space-y-4">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => onChangeSearch(e)}
                style={{ width: "450px" }}
                placeholder="Search"
                className="w-1/3 rounded-full pl-4 border-0 bg-gray-300 p-2 outline-none"
              />
              <div className="grid grid-cols-3 gap-4 w-full">
                {dataList.map((item, index) => (
                  <Card key={index} data={item} index={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PortfoliosLayout;
