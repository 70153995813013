export function getDoublewriteConfig(currentEnv) {
  const configs = {
    production: {
      url: process.env.REACT_APP_BASE_OPENAPIPAAS_STAGING_URL,
      headers: {
        "api-key": process.env.REACT_APP_DEVELOPER_OPENAPIPAAS_STAGING_API_KEY,
        "project-id": process.env.REACT_APP_DEVELOPER_OPENAPIPAAS_STAGING_PROJECT_ID,
        "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
      },
    },
    staging: {
      url: process.env.REACT_APP_BASE_OPENAPIPAAS_SANDBOX_URL,
      headers: {
        "api-key": process.env.REACT_APP_DEVELOPER_OPENAPIPAAS_SANDBOX_API_KEY,
        "project-id": process.env.REACT_APP_DEVELOPER_OPENAPIPAAS_SANDBOX_PROJECT_ID,
        "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
      },
    },
  };

  return configs[currentEnv] || {};
}