import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { Checkbox, Typography } from "@material-tailwind/react";
import { urls, commonConfigs } from "../../../config/config";
import { InfoCircle } from "iconsax-react";
import { INVOICE_PLAN_SYSCODE } from "../../../commons/constants/invoicePlanTypeSyscode";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const CreateSandbox = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [optionLoad, setOptionLoad] = useState(true);
  const [projects, setProjectsData] = useState([]);
  const [developerProjName, setDeveloperProjName] = useState();
  const [load, setLoad] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      subscription_id: null
    },
  });
  const createSandboxUrl = urls.sandboxUrl + commonConfigs.apiUrls.CreateDeveloperProject()
  const getProjectsUrl = urls.sandboxUrl + commonConfigs.apiUrls.getProjects()

  useEffect(() => {
    async function getProjectsData() {
      await axios
        .get(getProjectsUrl, { headers: commonConfigs.sandboxApiHeader })
        .then((res) => {
          // console.log(res.data.result.data)
          const projects = res.data.result.data.filter((project) => project.project_name === "sandbox_nets")
          setProjectsData(projects);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (optionLoad) {
      getProjectsData().catch((err) => {
        console.log("err for projects : " + err);
      });
    }
  }, []);

  const apiOptions = projects.map((project) => ({
    value: project.project_id,
    label: project.project_name
  }))
  
  function formatData(data) {
    var newData = data;
    var developerSsoId = localStorage.getItem("ssoId");

    if (data.access) {
      var access = [];
      if (data.access.length > 0) {
        access = data.access.map(item => ({ project_id: item.value }));
      }
    } else {
      access = null;
    }
    newData.invoice_plan_syscode = INVOICE_PLAN_SYSCODE.Reserved;
    newData.developer_sso_id = developerSsoId;
    newData.developer_proj_name = developerProjName;
    newData.access = access;
    // console.log("formatdata", newData);
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button

    const newData = formatData(data);

    // console.log("formatted data", newData);

    // console.log("newData", newData);
    // console.log(createSandboxUrl)
    axios
      .post(createSandboxUrl, newData, {
        headers: commonConfigs.sandboxApiHeader,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          window.location.href = `/homepage`;
        }
      })
      .catch((err) => {
        alert(`Error: ${err}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  if (!load) {
    return (
      <div className="flex justify-center items-center min-h-screen" style={{ marginTop: "-1.5%"}}>
        <div className="mt-16 desktop:space-x-8  mobile:pt-2 mobile:pb-4 mobile:space-x-2" style={{ textAlign: "-webkit-center", marginTop: "0px", width: "100%" }}>
          <div className="bg-white border-white border rounded-2xl m-5 p-4" style={{height: "inherit", width: "65%", minHeight: 0, minWidth: 0}}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="mt-2">
                <label htmlFor="developer_proj_name" className="block text-sm font-medium text-gray-600 mt-8" style={{ textAlign: "left", marginBottom: "5px" }}>
                  Sandbox Name
                </label>
                      <div className="relative rounded-md shadow-sm" style = {{color: "black"}}>
                        <input
                          type="text"
                          name="developer_proj_name"
                          id="developer_proj_name"
                          className={
                            !errors.developer_proj_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Name of Sandbox"
                          {...register("developer_proj_name", { required: true })}
                          value={developerProjName}
                          onChange={(e) => setDeveloperProjName(e.target.value)}
                        />
                        {errors.developer_proj_name?.type === "required" && errorIcon}
                      </div>
                    </div>
              </div>
              <div className="mt-8 sm:col-span-2 mb-8" style={{color: "black"}}>
                <Controller
                  name="access"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={apiOptions}
                      isSearchable={true}
                      isMulti={true}
                      placeholder="Select API"
                      className="text-sm"
                    />
                  )}
                />
              </div>
              <label
                htmlFor="developer_proj_website_url"
                className="block text-sm font-medium text-black"
                style={{ textAlign: "left", marginBottom: "5px", marginTop: "-10px" }}
              >
                Project Website URL
              </label>
              <div className="relative rounded-md shadow-sm mb-2 text-black">
                <input
                  type="text"
                  name="developer_proj_website_url"
                  id="developer_proj_website_url"
                  className={
                    !errors.developer_proj_website_url
                      ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                      : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                  }
                  placeholder="Enter Project Website URL"
                  {...register("developer_proj_website_url", { required: true })}
                  style={{marginBottom: "20px"}}
                />
                {errors.developer_proj_website_url?.type === "required" && errorIcon}
              </div>

              {/* create button */}
              <div>
                <Link to="/homepage">
                  <button
                    type="button"
                    className="hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 font-bold py-2 px-12 rounded-full ml-5 mt-10"
                    disabled={isDisabled}
                  >
                    Cancel
                  </button>
                </Link>
                  <button
                    type="submit"
                    className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full ml-5 mt-10"
                    disabled={isDisabled}
                  >
                    Create
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default CreateSandbox;
