import { Link } from "react-router-dom";
import { format } from "date-fns";

const MembershipCard = ({ text, header, icon, onClick }) => {
  return (
    <>
      <div
        className="bg-white text-black rounded-3xl p-5 w-80 h-52 flex justify-center items-center shadow-md transition-transform duration-300 hover:scale-105"
        style={{ flexDirection: "column" }}
        onClick={onClick}
      >
        <div>
          <img src={icon} className="h-16 w-16" style={{ marginBottom: "30%" }} />
        </div>
        <h3
          className="font-bold"
          style={{ fontSize: "19px", marginBottom: "5%" }}
        >
          {header}
        </h3>
        <p className="text-center" style={{ marginBottom: "10%" }}>
          {text}
        </p>
      </div>
    </>
  );
};

export default MembershipCard;
