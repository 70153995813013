import React, { useState } from "react";
import UpdateProjectModal from "./updateProjectModal";
import { PlatformSyscodeString } from "../../../commons/constants/platformTypeSyscode";
import { Link, useNavigate } from "react-router-dom";

const DetailsCard = ({ data, environment }) => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();
  const handleViewSecret = () => {
    alert(`Project API Key: ${data.developer_proj_key_value}`);
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div
      style={{
        marginRight: "10px",
        marginLeft: "80px",
        width: "275%",
        marginTop: "-60px",
        color: "black",
      }}
    >
      <div
        className="mb-4"
        style={{
          textAlign: "left",
          marginBottom: "40px",
          fontSize: "x-large",
          marginLeft: "-3.5%",
        }}
      >
        Community Project
      </div>
      <div
        className="mb-4 font-bold"
        style={{
          textAlign: "left",
          marginTop: "35px",
          color: "black",
          fontSize: "17px",
        }}
      >
        {data.developer_proj_name}
      </div>
      <div
        className="bg-white rounded-xl"
        style={{
          height: "inherit",
          width: "100%",
          minHeight: 0,
          minWidth: 0,
          boxShadow: "5px 10px #EBECF0",
          color: "black",
          fontSize: "16px",
          borderWidth: "0.5px",
          borderColor: "black",
          borderStyle: "solid",
          marginTop: "-10px",
        }}
      >
        <div
          className="p-4"
          style={{
            display: "grid",
            gridTemplateAreas: `'text1 text1 text1' 'text2 text2 text2''text3 text3 text3''text4 text4 text4''text5 text5 text5''text6 text6 text6''text7 text7 text7''button1 button1 button1'`,
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gap: 4,
            maxWidth: "inherit",
          }}
        >
          <div
            style={{ gridArea: "text1", textAlign: "left", marginTop: "10px" }}
          >
            <strong>Developer Project Id</strong>: {data.developer_proj_id}
          </div>
          <div style={{ gridArea: "text3", textAlign: "left" }}>
            <strong>Project API Key</strong>:
            <button
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "5px",
                marginLeft: "20px",
              }}
              className="bg-blue text-white-500 hover:bg-blue-500 hover:text-white outline py-2 px-10 font-bold mr-6 text-sm mt-8 mb-4"
              onClick={handleViewSecret}
            >
              Click here to view API key
            </button>
          </div>
          <div style={{ gridArea: "text4", textAlign: "left" }}>
            <strong>Project Secret Key</strong>: {data.developer_proj_scrt_key}
          </div>
          <div
            style={{ gridArea: "text5", textAlign: "left", marginTop: "20px" }}
          >
            <strong>Project Platform Type</strong>:{" "}
            {PlatformSyscodeString[data.platform_syscode]} (platform-syscode:{" "}
            {data.platform_syscode})
          </div>
          <div
            style={{ gridArea: "text6", textAlign: "left", marginTop: "20px" }}
          >
            <strong>Project Website URL</strong>:{" "}
            {data.developer_proj_website_url || "None"}
          </div>
          <div
            style={{ gridArea: "text7", textAlign: "left", marginTop: "20px" }}
          >
            <strong>Enabled APIs for Project</strong>:
            <table
              style={{
                width: "100%",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    API Name
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    URL
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Organization ID
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.access
                  .filter(
                    (project) =>
                      project.project_name !== "beveatshop" &&
                      project.project_name !== "spaerohub"
                  )
                  .map((project, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {project.project_name}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {project.project_url.split("|").map((url, urlIndex) => (
                          <div key={urlIndex}>{url}</div>
                        ))}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {project.org_id}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* buttons */}
          <div style={{ gridArea: "button1" }}>
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                marginBottom: "-20px",
              }}
            >
              <button
                className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-10 rounded-full font-bold mr-6 text-sm mt-8 mb-5"
                onClick={() => setUpdateModalOpen(true)}
                style={{ marginRight: "24px" }}
              >
                Update
              </button>
              {isUpdateModalOpen && (
                <UpdateProjectModal
                  setOpenModal={setUpdateModalOpen}
                  load={setLoad}
                  data={data}
                  environment={environment}
                />
              )}
              <button
                onClick={handleBack}
                className="hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 py-2 px-10 rounded-full font-bold text-sm mt-8 mb-5"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCard;
