import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { InfoCircle } from "iconsax-react";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { commonConfigs, urls } from "../../../../config/config";
import { getUser } from "../../../../services/tokenService";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateCertificateDetailModal({
  setOpenModal,
  planId,
  devProjId,
  data
}) {

  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const [developerName, setDeveloperName] = useState();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      developer_name: data.developer_name,
      developer_email: data.developer_email
    }
  });
  console.log(planId)
  let header;
  let updateCertificateUrl;
  updateCertificateUrl = 
  urls.resourceOwnerUrl + 
  commonConfigs.apiUrls.updateCertificate()
  header = commonConfigs.resourceOwnerApiHeader;

  function formatData(data) {
    var newData = data;
    const accountId = getUser()?.account_id;

    // console.log("formatdata", newData);
    newData.account_id = accountId;
    newData.invoice_plan_id = planId;
    newData.developer_proj_id = devProjId;
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    var newData = formatData(data);

    // console.log("formatted data", newData);

    axios
      .put(updateCertificateUrl, newData, {
        headers: header,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          setOpenModal(false);
          window.location.reload()
        }
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error: ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                      style={{ marginBottom: "25px", marginTop: "-10px" }}
                    >
                      Certificate Details
                    </Dialog.Title>
                    <div className="mt-2">
                      <label
                        htmlFor="developer_name"
                        className="block text-sm font-medium text-gray-800"
                      >
                        Name on Certificate
                      </label>
                      <div
                        className="relative rounded-md shadow-sm"
                        style={{ marginBottom: "20px" }}
                      >
                        <input
                          type="text"
                          name="developer_name"
                          id="developer_name"
                          className={
                            !errors.developer_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Name"
                          {...register("developer_name", {
                            required: true,
                          })}
                          value={developerName}
                          onChange={(e) => setDeveloperName(e.target.value)}
                        />
                        {errors.developer_name?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <label
                      htmlFor="developer_email"
                      className="block text-sm font-medium text-gray-600"
                      style={{
                        textAlign: "left",
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      Email
                    </label>
                    <div className="relative rounded-md shadow-sm mb-2">
                      <input
                        type="text"
                        name="developer_email"
                        id="developer_email"
                        className={
                          !errors.developer_email
                            ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                            : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        }
                        placeholder="Enter Email"
                        {...register("developer_email")}
                        style={{ marginBottom: "20px" }}
                      />
                      {errors.developer_email?.type === "required" &&
                        errorIcon}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent rounded-md px-4 py-2 border-none hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                    style={{ marginTop: "16px" }}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 font-bold sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}