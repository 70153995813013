import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { InfoCircle } from "iconsax-react";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { commonConfigs, urls } from "../../../config/config";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateAccessPageModal({ setOpenModal, row, load }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      access_name: row.access_name,
      access_name_option: row.access_name_option,
    },
  });
  const accessId = row.access_id;
  let updateAccessPageUrl = ``;
  let apiHeaders;

  if (process.env.REACT_APP_ENV === "staging") {
    updateAccessPageUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.updateAccessPage(accessId);
    apiHeaders = commonConfigs.apiHeader;
  } else {
    updateAccessPageUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.updateAccessPage(accessId);
    apiHeaders = commonConfigs.prodApiHeader;
  }

  function formatData(data) {
    var newData = data;
    newData.access_name = data.access_name ? data.access_name : null;
    newData.access_name_option = data.access_name_option ? data.access_name_option : null;
    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button
    formatData(data);
    axios
      .put(updateAccessPageUrl, data, {
        headers: apiHeaders,
      })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          load(true);
          setOpenModal(false);
          window.location.reload();  
        }
      })
      .catch((err) => {
        alert(`Error: ${err}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 mb-4"
                    style={{ marginBottom: "40px", marginTop: "-30px" }}
                  >
                    Update Access Page
                  </Dialog.Title>
                  <div className="mt-2">
                    <label
                      htmlFor="access_name"
                      className="block text-sm font-medium text-gray-800"
                    >
                      Access Name
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name="access_name"
                        id="access_name"
                        className={
                          !errors.access_name
                            ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                            : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        }
                        placeholder="Enter Access Name"
                        {...register("access_name", {
                          required: true,
                        })}
                      />
                      {errors.access_name?.type === "required" && errorIcon}
                    </div>
                  </div>

                  <label
                    htmlFor="access_name_option"
                    className="block text-sm font-medium text-gray-800 mt-4"
                  >
                    Access Name Option
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="access_name_option"
                      id="access_name_option"
                      className={
                        !errors.access_name_option
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder="Enter Access Name Option"
                      {...register("access_name_option", {
                        required: false,
                      })}
                    />
                    {errors.access_name_option?.type === "required" && errorIcon}
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  className="mt-3 w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  disabled={isDisabled} // Disable the button when isDisabled is true
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
              </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
