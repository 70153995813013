export const PLATFORM_SYSCODE = {
  eCommerce_Website: 100,
  eCommerce_Mobile: 101,
  eCommerce_Kiosk: 102,
}

export const PlatformSyscodeString = {
  [PLATFORM_SYSCODE.eCommerce_Website]: "Website",
  [PLATFORM_SYSCODE.eCommerce_Mobile]: "Mobile",
  [PLATFORM_SYSCODE.eCommerce_Kiosk]: "Kiosk",
};