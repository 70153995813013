const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.refreshToken;
  };
  
  const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.access_token;
  };
  
  const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem('user'));
    user.access_token = token;
    localStorage.setItem('user', JSON.stringify(user));
  };
  
  const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
  };
  
  const setUser = (user) => {
    // console.log(JSON.stringify(user));
    localStorage.setItem('user', JSON.stringify(user));
  };
  
  const removeUser = () => {
    // localStorage.removeItem('user');
    localStorage.clear();
  };
  
  export {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
  };