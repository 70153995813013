import React from "react";
import "react-app-polyfill/ie11"; 
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider 
  store={store}
  >
    <App />
  </Provider>
);

