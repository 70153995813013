import { InfoCircle } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CertificationTabs from "../components/certificationTabs";
import { certificationCurrentTabContext } from "../context/certificationContext";
import CertificationForm from "../components/certificationForm";
import UpdateCertificateDetailModal from "../components/updateCertificateDetailModal";
import { commonConfigs, urls } from "../../../../config/config";
import { getUser } from "../../../../services/tokenService";
import axios from "axios";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const CertificationLayout = () => {
  const [load, setLoad] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const location = useLocation();
  const { certificationPlanId, environment, planName, devProjId } =
    location.state || {};
  const navigate = useNavigate();
  const { currentTab } = useContext(certificationCurrentTabContext);
  const accountId = getUser()?.account_id;
  const getDeveloperCertificatesUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.getAllDeveloperCertificates(accountId);

  async function getDeveloperCertificates() {
    await axios
      .get(getDeveloperCertificatesUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        const response = res.data.result.data;
        const developerCertificate = response.filter(
          (certificate) =>
            certificate.invoice_plan_id == certificationPlanId &&
            certificate.developer_proj_id == devProjId
        );
        setCertificates(developerCertificate);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDeveloperCertificates().catch((err) => {
      console.log("err for getting developer certificates: " + err);
    });
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleEdit = () => {
    setOpen(true);
  };

  if (!load) {
    return (
      <>
        <div className="p-6">
          {/* Back Button */}
          <div className="flex justify-start mb-4">
            <button
              type="button"
              onClick={goBack}
              className="hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 font-bold py-2 px-6 rounded-md"
            >
              Back
            </button>
          </div>

          {/* Course Title */}
          <div className="text-xl font-bold mb-6 text-left text-black">
            {planName}
          </div>

          {/* Certificate Details Card */}
          <div className="bg-white rounded-xl p-6 shadow-md border border-gray-300 mb-8 text-black">
            <div>
              {/* Left Section: Name, Email, and Note */}
              <div className="text-left">
                <div className="text-lg mb-2">
                  <b>Name on Certificate: </b>
                  {certificates[0].developer_name}
                </div>
                <div className="text-lg mb-4">
                  <b>Email: </b>
                  {certificates[0].developer_email}
                </div>
                <div className="text-sm text-black flex items-start space-x-2">
                  <InfoCircle className="h-5 w-5" aria-hidden="true" />
                  <p>
                    <b>Note:</b> Once certification is completed for any
                    coursework, the name on the certificate and the email cannot
                    be changed.
                  </p>
                </div>
              </div>

              {!certificates.some(
                (certificate) =>
                  certificate.txn_id !== null &&
                  certificate.txn_id !== undefined
              ) && (
                <>
                  <div className="text-right">
                    <button
                      type="button"
                      onClick={handleEdit}
                      disabled={certificates.some(
                        (certificate) =>
                          certificate.txn_id !== null &&
                          certificate.txn_id !== undefined
                      )}
                      className={`font-bold py-2 px-6 rounded-md ${
                        certificates.some(
                          (certificate) =>
                            certificate.txn_id !== null &&
                            certificate.txn_id !== undefined
                        )
                          ? "bg-blue-500 text-white cursor-not-allowed" // Styles for the disabled state
                          : "hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500" // Styles for the enabled state
                      }`}
                      // className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-6 rounded-md"
                    >
                      Edit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Tab Navigation */}
          <CertificationTabs />

          {/* NETS QR Form */}
          {currentTab.name === "NETS QR" && (
            <>
              <CertificationForm
                tab={currentTab}
                certificateData={certificates}
                environment={environment}
              />
            </>
          )}
          {currentTab.name === "NETS Click" && (
            <>
              <CertificationForm
                tab={currentTab}
                certificateData={certificates}
                environment={environment}
              />
            </>
          )}
          {currentTab.name === "eNETS" && (
            <>
              <CertificationForm
                tab={currentTab}
                certificateData={certificates}
                environment={environment}
              />
            </>
          )}
        </div>
        {isOpen && (
          <UpdateCertificateDetailModal
            setOpenModal={setOpen}
            planId={certificationPlanId}
            devProjId={devProjId}
            environment={environment}
            data={certificates[0]}
          />
        )}
      </>
    );
  }
};

export default CertificationLayout;
