import CertificationLayout from "./certificationLayout";
import { CertificationContextProvider } from "../context/certificationContext";

const CertificationPage = () => {
  return (
    <CertificationContextProvider>
        <CertificationLayout />
    </CertificationContextProvider>
  );
};

export default CertificationPage;