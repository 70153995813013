import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { commonConfigs, urls } from "../../../config/config";
import { InfoCircle } from "iconsax-react";
import { getDoublewriteConfig } from "../../../commons/utils/getDoublewriteConfig";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function UpdateProjectModal({
  setOpenModal,
  data,
  load,
  environment,
}) {
  const developerProjId = data.developer_proj_id;

  const [isDisabled, setIsDisabled] = useState(false);
  const [optionLoad, setOptionLoad] = useState(true);
  const [open, setOpen] = useState(true);
  const [developerProjName, setDeveloperProjName] = useState();
  const [projects, setProjectsData] = useState([]);
  const [lazyData, setLazyData] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      developer_proj_name: data.developer_proj_name,
      subscription_id: null,
      developer_proj_website_url: data.developer_proj_website_url,
    },
  });
  let updateProjectUrl = `${
    urls.resourceOwnerUrl
  }${commonConfigs.apiUrls.UpdateDeveloperProject(developerProjId)}`;
  let getProjectsUrl = `${
    urls.resourceOwnerUrl
  }${commonConfigs.apiUrls.getProjects()}`;

  const apiOptions = projects.map((project) => ({
    value: project.project_id,
    label: project.project_name,
  }));

  const options_plan = [
    { value: null, label: "None" },
    { value: "1", label: "Subscription 1" },
    { value: "2", label: "Subscription 2" },
  ];

  async function updateDoublewriteProject(body) {
    const { url, headers } = getDoublewriteConfig(process.env.REACT_APP_ENV);
    const doublewriteUrl =
      url + commonConfigs.apiUrls.UpdateDeveloperProject(developerProjId);
    try {
      await axios.put(doublewriteUrl, body, { headers: headers });
    } catch (error) {
      console.error("Error updating doublewrite project:", error);
    }
  }

  function formatData(data) {
    var newData = data;
    var developerSsoId = localStorage.getItem("ssoId");
    // var subscriptionId = data.subscription_id.value;

    // if (data.access) {
    //   var access = [];
    //   if (data.access.length > 0) {
    //     access = data.access.map((item) => ({ project_id: item.value }));
    //   }
    // } else {
    //   access = null;
    // }

    // console.log("formatdata", newData);
    newData.developer_sso_id = developerSsoId;
    newData.developer_proj_name = developerProjName;
    // newData.subscription_id = subscriptionId;
    newData.access = null;
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button
    var newData = formatData(data);

    // console.log("formatted data", newData);

    axios
      .put(updateProjectUrl, newData, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then(async (res) => {
        await updateDoublewriteProject(newData);
        alert("success");
        setIsDisabled(false); // Enable the submit button after API call
        load(true);
        setOpenModal(false);
        window.location.reload();
      })
      .catch((err) => {
        const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error: ${err_msg}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  // useEffect(() => {
  //   async function getProjectsData() {
  //     await axios
  //       .get(getProjectsUrl, {
  //         headers: header,
  //       })
  //       .then((res) => {
  //         // console.log(res.data.result.data);
  //         setProjectsData(res.data.result.data);
  //         const projectsFound = res.data.result.data.filter((project) =>
  //           data.access.some(
  //             (access) => access.project_name === project.project_name
  //           )
  //         );

  //         var formattedAccess = [];
  //         projectsFound.forEach((access) => {
  //           formattedAccess.push({
  //             value: access.project_id,
  //             label: access.project_name,
  //           });
  //           setLazyData((lazyData["access"] = formattedAccess));
  //         });

  //         setOptionLoad(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   if (optionLoad) {
  //     const getSelectData = async () => {
  //       await getProjectsData().catch((err) => {
  //         console.log("err for projects : " + err);
  //       });
  //     };

  //     getSelectData()
  //       .then(() => {
  //         console.log("getSelectData success");
  //         reset(lazyData);
  //       })
  //       .catch((err) => {
  //         console.log("err for selected data: " + err);
  //       });
  //   }
  // }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity"
              onClick={() => setOpenModal(false)}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                      style={{ marginBottom: "25px", marginTop: "-10px" }}
                    >
                      Update Community Project
                    </Dialog.Title>
                    <div className="mt-2">
                      <label
                        htmlFor="developer_proj_name"
                        className="block text-sm font-medium text-gray-800"
                      >
                        Project Name
                      </label>
                      <div
                        className="relative rounded-md shadow-sm"
                        style={{ marginBottom: "20px" }}
                      >
                        <input
                          type="text"
                          name="developer_proj_name"
                          id="developer_proj_name"
                          className={
                            !errors.developer_proj_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Project Name"
                          {...register("developer_proj_name", {
                            required: true,
                          })}
                          value={developerProjName}
                          onChange={(e) => setDeveloperProjName(e.target.value)}
                        />
                        {errors.developer_proj_name?.type === "required" &&
                          errorIcon}
                      </div>
                    </div>
                    {/* <div
                      className="sm:col-span-6 mt-2"
                    >
                      <Controller
                        name="access"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable
                            {...field}
                            options={apiOptions}
                            isSearchable={true}
                            placeholder="Select API"
                            className="text-sm"
                            isMulti={true}
                            maxMenuHeight={"200px"}
                          />
                        )}
                      />
                    </div> */}
                    <label
                      htmlFor="developer_proj_website_url"
                      className="block text-sm font-medium text-gray-600"
                      style={{
                        textAlign: "left",
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      Project Website URL
                    </label>
                    <div className="relative rounded-md shadow-sm mb-2">
                      <input
                        type="text"
                        name="developer_proj_website_url"
                        id="developer_proj_website_url"
                        className={
                          !errors.developer_proj_website_url
                            ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                            : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        }
                        placeholder="Enter Project Website URL"
                        {...register("developer_proj_website_url")}
                        style={{ marginBottom: "20px" }}
                      />
                      {errors.developer_proj_website_url?.type === "required" &&
                        errorIcon}
                    </div>
                    {/* <div className="mt-1 sm:col-span-2 mb-8">
                      <label
                        htmlFor="subscription_id"
                        className="block text-sm font-medium text-gray-800"
                        style={{ marginTop: "15px" }}
                      >
                        Subscription plan
                      </label>
                      <Controller
                        name="subscription_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            required={true}
                            isClearable
                            {...field}
                            options={options_plan}
                            isSearchable={false}
                            placeholder="Select Subscription Plan"
                            className="text-sm"
                            maxMenuHeight={80}
                            menuPlacement="auto"
                          />
                        )}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center border-transparent px-4 py-2 border-none text-base font-medium text-yellow-800 hover:text-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    disabled={isDisabled} // Disable the button when isDisabled is true
                    style={{ marginTop: "16px" }}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 border-none bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
