import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

/**
 * @desc Function for generating table pagination
 * @param {*} props Consisting state, pageOptions, goToPage, nextPage, previousPage, setPage size
 * @returns A UI Component of pagination for table
 */

function Pagination(props) {
  const { state, pageOptions, gotoPage, nextPage, previousPage } = props;
  const currentPage = state.pageIndex;

  const maxButton = 5;

  const onNext = () => {
    nextPage();
  };

  const onPrevious = () => {
    previousPage();
  };

  return (
    <>
      <nav className="my-3 px-4 flex  flex-col items-center justify-between sm:px-0">
        <div className="hidden md:-mt-px md:flex">
          <button
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => onPrevious()}
          >
            <ArrowLeft2 className="h-5 w-5" aria-hidden="true" />
          </button>
          {pageOptions.map((page) => {
            if (currentPage === page) {
              return (
                <button
                  key={page}
                  onClick={() => gotoPage(page)}
                  className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            }
            if (pageOptions.length <= maxButton) {
              return (
                <button
                  key={page}
                  onClick={() => gotoPage(page)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            } else if (
              (currentPage <= 2 && page < 5) ||
              (currentPage >= pageOptions.length - 2 &&
                page >= pageOptions.length - 5)
            ) {
              return (
                <button
                  key={page}
                  onClick={() => gotoPage(page)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            } else if (currentPage + 2 >= page && currentPage - 2 <= page) {
              return (
                <button
                  key={page}
                  onClick={() => gotoPage(page)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {page + 1}
                </button>
              );
            }
          })}
          <button
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => onNext()}
          >
            <ArrowRight2 className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </nav>
    </>
  );
}

export default Pagination;
