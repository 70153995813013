import { Link, useNavigate } from "react-router-dom";
import { getUser } from "../../../../../services/tokenService";
import axios from "axios";
import { useEffect, useState } from "react";
import { urls, commonConfigs } from "../../../../../config/config";
import CreateCertificateDetails from "./createCertificateDetailsModal";
import { INVOICE_PLAN_SYSCODE } from "../../../../../commons/constants/invoicePlanTypeSyscode";

export default function CourseCard({
  industry,
  plan,
  currentTab,
  price,
  planId,
  benefits,
  onButtonClick,
  devProjId,
}) {
  const [certificates, setCertificates] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [invoicePlanSyscode, setInvoicePlanSyscode] = useState(null);
  const navigate = useNavigate();
  const ssoId = getUser()?.sso_id;
  const accountId = getUser()?.account_id;
  const getDeveloperCertificatesUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.getAllDeveloperCertificates(accountId);

  async function getDeveloperCertificates() {
    await axios
      .get(getDeveloperCertificatesUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        setCertificates(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDeveloperCertificates().catch((err) => {
      console.log("err for getting developer certificates: " + err);
    });
    let invoicePlanSyscode;
    if (currentTab.name === "Certification") {
      const isGoLive =
        plan.certification_category.certification_category.includes("Go Live");
      invoicePlanSyscode = isGoLive
        ? INVOICE_PLAN_SYSCODE.Certification_Plan_Go_Live
        : INVOICE_PLAN_SYSCODE.Certification_Plan_UAT;
    } else {
      const isProduction = currentTab.name !== "Staging";
      environment = isProduction
        ? { value: "1", label: "Production" }
        : { value: "0", label: "Staging" };
      invoicePlanSyscode = isProduction
        ? INVOICE_PLAN_SYSCODE.API_Plan_Go_Live
        : INVOICE_PLAN_SYSCODE.API_Plan_UAT;
    }
    setInvoicePlanSyscode(invoicePlanSyscode)
  }, []);

  const onGetCertificateClick = () => {
    const developerCertificate = certificates.find(
      (certificate) =>
        certificate.invoice_plan_id == planId &&
        certificate.developer_proj_id == devProjId
    );
    if (developerCertificate) {
      navigate("/subscriptions/certification", {
        state: {
          planName: plan.certification_plan,
          certificationPlanId: planId,
          environment: "staging",
          devProjId: devProjId,
        },
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className="bg-white shadow-lg rounded-lg w-80 h-[26rem] flex flex-col justify-between">
        <div className="p-6 text-black">
          <h2
            className="text-gray-700 font-bold mb-2"
            style={{ fontSize: "25px" }}
          >
            Industry
          </h2>
          <div className="bg-blue-400 text-white font-bold text-lg py-1 rounded-full mb-2">
            {industry}
          </div>
          <p className="text-3xl font-bold text-gray-800 mb-1">
            <span className="line-through text-black">${price}</span>
          </p>
          <p className="text-red-600 text-sm mb-4">with Course Code</p>
          <ul
            className="text-left"
            style={{ listStyleType: "circle", paddingLeft: "20px" }}
          >
            {benefits.map((benefit, index) => (
              <li key={index} className="mb-2">
                {benefit}
              </li>
            ))}
          </ul>
        </div>
        <div>
          {devProjId ? (
            <div className="flex border-t border-white">
              <Link
                to={`/projects/details`}
                className="flex-1 bg-blue-400 text-white py-3 font-semibold hover:bg-blue-600"
                state={{
                  developerSsoId: ssoId,
                  developerProjId: devProjId,
                  environment: "staging",
                }}
              >
                <button>View Project Details</button>
              </Link>
              <div className="w-[1px] bg-white"></div>
              <button
                onClick={onGetCertificateClick}
                className="flex-1 bg-blue-400 text-white py-3 font-semibold hover:bg-blue-600"
              >
                Get Course Certificate
              </button>
            </div>
          ) : (
            <button
              onClick={onButtonClick}
              className="bg-blue-400 text-white py-3 px-4 w-full font-semibold hover:bg-blue-600"
            >
              Apply Course Code
            </button>
          )}
        </div>
        {isOpen && (
          <CreateCertificateDetails
            setOpenModal={setOpen}
            invoicePlanSyscode={invoicePlanSyscode}
            planName={plan.certification_category.certification_category}
            planId={planId}
            devProjId={devProjId}
            environment={"staging"}
          />
        )}
      </div>
    </>
  );
}
