import { Navigate } from "react-router-dom";
import { isLogin } from "./authenticate";

export function DevPrivateRoute({ children }) {
  return isLogin() ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
}
